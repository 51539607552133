import { inject, Injectable, signal, WritableSignal } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Network } from '@capacitor/network';

enum SETTINGS_MODE {
  POS = 'pos',
  AUTO_CHOICE = 'auto_choice',
  FUEL = 'fuel'
}

interface Settings {
  mode: SETTINGS_MODE.POS | SETTINGS_MODE.AUTO_CHOICE | SETTINGS_MODE.FUEL;
  language: 'en';
}

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  private translate = inject(TranslateService);

  private settingsSignal: WritableSignal<Settings | null> =
    signal<Settings | null>(this.getSettingsFromLocalStorage());

  constructor() {
  }

  init() {
    this.translate.setDefaultLang(this.settingsSignal()?.language || 'en');
  }

  /**
   * Retrieves settings from local storage
   */
  private getSettingsFromLocalStorage(): Settings | null {
    const settings = localStorage.getItem('settings');
    return settings ? JSON.parse(settings) : null;
  }

  /**
   * Saves settings to local storage and updates the signal
   * @param settings
   */
  private setSettingsToLocalStorage(settings: Settings): void {
    const settingsString = JSON.stringify(settings);
    localStorage.setItem('settings', settingsString);
    this.settingsSignal.set(settings);
  }

  /**
   * Removes settings from local storage and updates the signal
   */
  clear(): void {
    localStorage.removeItem('auth_token');
    this.settingsSignal.set(null);
  }

  /**
   * Retrieves settings
   * @returns Settings or null
   */
  getSettings(): Settings | null {
    return this.settingsSignal();
  }

  logCurrentNetworkStatus = async () => {
    return await Network.getStatus();
  };

  switchLanguage(lang: string) {
    this.translate.use(lang);
  }
}
